import axios from "axios";
import ApiSpec from "../features/EndPoints";
import {
  notifiToasterSuccess,
  notifiToasterWarn,
} from "../../components/common/UseToast";
import LiveViewAction from "./LiveViewAction";
import SystemParametersEndpoints from "../features/SystemParametersEndpoints";
import { default_empty_schedule } from "../../pages/SystemParameters/utils/data";
import { BACKEND_URL, LIVEVIEW_SERVER_URL } from "../../routes/config";

class VmsLiveviewActions {
  static UpdateLiveviewFeature(data) {
    return async (dispatch) => {
      dispatch({
        type: "UPDATE_LIVEVIEW_FEATURE_VMS",
        payload: data,
      });
    };
  }

  // Dispatches an action to add an array of cameras to the playing camera list in the Redux store.
  static updateSelectedCameraList(data) {
    return async (dispatch) => {
      dispatch({
        type: "ADD_CAMERA_TO_SELECTED_CAMERA_LIST",
        payload: data,
      });
    };
  }

  /**
   * Dispatches an action to replace an array of cameras to the playing camera list in the Redux store.
   *
   * @param {Array} data - An array of camera objects to be replaced to the playing camera list.
   * @returns {Function} An async thunk action that dispatches the action to add cameras.
   */
  static replaceSelectedCameraList(data) {
    return async (dispatch) => {
      dispatch({
        type: "REPLACE_CAMERAS_TO_SELECTED_CAMERA_LIST",
        payload: data,
      });
    };
  }

  static removeCameraFromLiveViewData(data, playlist) {
    return async (dispatch) => {
      playlist.forEach((item) => {
        if (data.includes(item.cam_id)) {
          LiveViewAction.stopLiveStream({
            stream_id: item.stream_id,
            vpn_tunnel: item.vpn_tunnel,
          })(dispatch);
        }
      });

      dispatch({
        type: "REMOVE_SELECTED_LIVE_FEEDS_VMS",
        payload: data,
      });
    };
  }

  static replacePlayingList(data) {
    return async (dispatch) => {
      dispatch({
        type: "REPLACE_PLAYLIST_WITH_NEW_PLAYLIST",
        payload: data,
      });
    };
  }

  /**
   * Dispatches an action to update the current page in the Redux store.
   *
   * @param {number} page - The new page number to be set as the current page.
   * @returns {Function} An async thunk action that dispatches the action to update the current page.
   */
  static updateCurrentPage(page) {
    return async (dispatch) => {
      dispatch({
        type: "UPDATE_CURRENT_PAGE_VMS",
        payload: page,
      });
    };
  }

  static updateTempCurrentPage(page) {
    return async (dispatch) => {
      dispatch({
        type: "UPDATE_TEMP_CURRENT_PAGE_VMS",
        payload: page,
      });
    };
  }

  /**
   * Dispatches an action to update the selected grid size in the Redux store.
   *
   * @param {number} grid - The new grid size to be set as the selected grid.
   * @returns {Function} An async thunk action that dispatches the action to update the selected grid.
   */
  static updateSelectedGrid(grid) {
    return async (dispatch) => {
      dispatch({
        type: "UPDATE_SELECTED_GRID_VMS",
        payload: grid,
      });
    };
  }

  static updateSelectedQuality(data) {
    return async (dispatch) => {
      dispatch({
        type: "UPDATE_SELECTED_QUALITY_VMS",
        payload: data,
      });
    };
  }

  static updateShowAlertDialog(data) {
    return async (dispatch) => {
      dispatch({
        type: "SHOW_DIALOG_TO_CHNAGE_THE_CAMERA",
        payload: data,
      });
    };
  }

  static updateSelectedCameraForPlayback(data) {
    return async (dispatch) => {
      dispatch({
        type: "ADD_PLAYBACK_CAMERAS_IN_VMS",
        payload: data,
      });
    };
  }

  static removeSelectedCameraForPlayback(data) {
    return async (dispatch) => {
      dispatch({
        type: "REMOVE_SELECTED_CAMERA_FROM_PLAYBACK_LIST",
        payload: data,
      });
    };
  }

  static removePlaybackStream() {
    return async (dispatch) => {
      dispatch({
        type: "REMOVE_PLAYBACK_STREAM_FOR_VMS",
      });
    };
  }

  // This function is used to fetch details of camera, NVR, and facility to display in the sidebar for live view.
  static getAllFacilitiesCameraLiveview(data) {
    return async (dispatch) => {
      try {
        let token = localStorage.getItem("token");
        let headers = token ? { Authorization: `Token ${token}` } : {};
        const response = await axios.get(ApiSpec.getAllFacilityCamera(data), {
          headers,
        });
        dispatch({
          type: "UPDATE_FACILITY_NVR_CAMERA_LIST_FOR_VMS",
          payload: response.data,
        });
      } catch (error) {
        console.error(
          "Error fetching facility camera live view details",
          error
        );
        notifiToasterWarn(
          "Failed to fetch facility camera live view details. Please try again."
        );
      }
    };
  }

  //this function is used to fetch details of camera , nvr and facility to display in side bar for playback only on those which support playback
  static getAllFacilitiesCameraPlayback(data) {
    return async (dispatch) => {
      let token = localStorage.getItem("token");
      let headers = token ? { Authorization: `Token ${token}` } : {};
      await axios
        .get(ApiSpec.getAllFacilityCameraPlayBack(data), { headers })
        .then((response) => {
          dispatch({
            type: "UPDATE_FACILITY_NVR_CAMERA_LIST_FOR_VMS",
            payload: response.data,
          });
        });
    };
  }

  /**
   * Fetches the available chunk of recording present in NVR hard disk.
   *
   * @param {Object} data - The data object containing playback details.
   * @param {string} data.cam_id - The ID of the camera.
   * @param {string} data.date - The date for which to fetch the playback chunk details.
   * @returns {Function} An async thunk action that dispatches the playback chunk details.
   */
  static getPlaybackChunkDetailsFromNvrAction(data) {
    return async (dispatch) => {
      try {
        let token = localStorage.getItem("token");
        let headers = token ? { Authorization: `Token ${token}` } : {};
        const response = await axios.get(
          ApiSpec.getPlaybackStreamInfo(
            data.cam_id,
            data.date,
            "00:00:00",
            "23:59:59"
          ),
          { headers }
        );

        if (!Array.isArray(response.data) || response.data.length === 0) {
          notifiToasterWarn(
            "Sorry, unable to find continuous playback intervals for the selected time."
          );

          dispatch({
            type: "UPDATE_PLAYBACK_CHUNKS_AVAILABLE",
            payload: [],
          });
        } else {
          dispatch({
            type: "UPDATE_TIME_LINE_FOR_RECORDING",
            payload: response.data,
          });
        }
      } catch (error) {
        dispatch({
          type: "UPDATE_TIME_LINE_FOR_RECORDING",
          payload: [],
        });
        console.log(error);
      }
    };
  }

  /**
   * Fetches the RTC stream to play for a camera based on quality, start time, and end time.
   *
   * @param {Object} data - The data object containing playback details.
   * @param {string} data.cam_id - The ID of the camera.
   * @param {string} data.date - The date for which to fetch the playback stream.
   * @param {string} data.startTime - The start time for the playback stream.
   * @param {string} data.endTime - The end time for the playback stream.
   * @returns {Function} An async thunk action that dispatches the playback stream.
   */
  static getPlaybackStream(data) {
    return async (dispatch) => {
      let token = localStorage.getItem("token");
      let headers = token ? { Authorization: `Token ${token}` } : {};
      await axios
        .get(
          ApiSpec.getPlaybackStream(
            data.cam_id,
            data.date,
            data.startTime,
            data.endTime
          ),
          { headers }
        )
        .then((response) => {
          if (response.data.length == 0) {
            notifiToasterWarn(
              "Sorry, there is no recording available for the selected time."
            );
          } else {
            dispatch({
              type: "UPDATE_PLAYBACK_STREAM_VMS",
              payload: [response.data],
            });
          }
        })
        .catch(() => {
          dispatch({
            type: "UPDATE_PLAYBACK_STREAM_VMS",
            payload: [],
          });
        });
    };
  }

  /**
   * Fetches and stores the available recordings of a camera based on the schedule.
   *
   * @param {Object} data - The data object containing camera details.
   * @param {string} data.cam_id - The ID of the camera.
   * @param {string} data.date - The date for which to fetch the recording details.
   * @returns {Function} An async thunk action that dispatches the available recording chunks.
   */
  static getAvailableRecordingChunk(data) {
    return async (dispatch) => {
      try {
        let token = localStorage.getItem("token");
        let headers = token ? { Authorization: `Token ${token}` } : {};
        // Making an async request using axios
        const response = await axios.get(
          ApiSpec.getAvailableRecordingDetails(
            data.cam_id,
            data.date,
            "00:00:00",
            "23:59:59"
          ),
          { headers }
        );

        // Check if the response data is empty and handle accordingly
        if (response.data.length === 0) {
          notifiToasterWarn(
            "Sorry, there is no recording available for the selected time."
          );
        } else {
          dispatch({
            type: "UPDATE_TIME_LINE_FOR_RECORDING",
            payload: response.data,
          });
        }
      } catch (error) {
        // Handling the error
        console.error("Error fetching recording details", error);
        dispatch({
          type: "UPDATE_TIME_LINE_FOR_RECORDING",
          payload: [],
        });
      }
    };
  }

  // Below function will edit camera details and save the recording in the redux state recording schedule
  // and update the modified details in the camera list array to make the array updated. It take camera id and data to update as parameters
  static patchCamera = (id, cameraData) => async (dispatch) => {
    try {
      let token = localStorage.getItem("token");
      let headers = token ? { Authorization: `Token ${token}` } : {};
      const response = await axios.patch(
        SystemParametersEndpoints.editCameraParameters(id),
        cameraData,
        { headers }
      );
      dispatch({
        type: "UPDATE_DAY_WISE_RECORDING_SCHEDULE",
        payload: response.data.recording_schedule,
      });
      notifiToasterSuccess("Camera edited successfully!");
    } catch (error) {
      console.error("Error updating cameras", error);
      notifiToasterWarn("Failed to update cameras. Please try again.");
    }
  };

  // This function will fetch the recording and save the recording in the redux state recording schedule
  // and update the modified details in the camera list array to make the array updated. It take camera id as a parameter
  static fetchRecordingScheduleCameraWise = (id) => async (dispatch) => {
    try {
      let token = localStorage.getItem("token");
      let headers = token ? { Authorization: `Token ${token}` } : {};
      const response = await axios.get(
        SystemParametersEndpoints.editCameraParameters(id),
        { headers }
      );
      const recordingSchedule =
        response.data.recording_schedule || default_empty_schedule;
      dispatch({
        type: "UPDATE_DAY_WISE_RECORDING_SCHEDULE",
        payload: recordingSchedule,
      });
      dispatch({
        type: "SYSTEM_PARAMTER_UPDATE_CAMERA_DETAIL",
        payload: response.data,
      });
    } catch (error) {
      console.error("Error fetching recording schedule", error);
      dispatch({
        type: "UPDATE_DAY_WISE_RECORDING_SCHEDULE",
        payload: default_empty_schedule,
      });
      notifiToasterWarn(
        "Failed to fetch recording schedule. Please try again."
      );
    }
  };

  static async deleteStream(streamName) {
    const deleteStreamUrl = `${BACKEND_URL}/delete-stream/${streamName}/`;
    const token = localStorage.getItem("token");
    const headers = token ? { Authorization: `Token ${token}` } : {};

    try {
      const response = await axios.delete(deleteStreamUrl, { headers });

      if (response.status === 200) {
        console.log(`Stream '${streamName}' deleted successfully.`);
      } else {
        console.log(
          `Failed to delete stream '${streamName}'. Status: ${response.status}`
        );
      }
    } catch (error) {
      console.error(`Error deleting stream '${streamName}':`, error.message);
      notifiToasterWarn("Failed to delete stream. Please try again.");
    }
  }
}

export default VmsLiveviewActions;
