import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { BACKEND_URL, LIVEVIEW_SERVER_URL } from "../../../routes/config";

const LiveViewVideoPlayer = ({ src }) => {
  useEffect(() => {
    // Function to send a PATCH request when the component mounts
    const updateStreamEndTime = async () => {
      const token = localStorage.getItem("token"); // Get token if needed for authentication

      const payload = {
        uuid: src.uuid,
        end_time: null,
      };

      try {
        const response = await fetch(`${BACKEND_URL}/update-stream/`, {
          method: "PATCH",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(payload),
        });

        if (!response.ok) {
          console.error(
            "Failed to update the stream end_time",
            await response.json()
          );
        } else {
          console.log("Stream end_time set to null successfully");
        }
      } catch (error) {
        console.error("Error while updating the stream:", error);
      }
    };

    // Call the update function when the component mounts
    setTimeout(() => {
      updateStreamEndTime();
    }, 2000);

    // Handle before page unload
    const handleBeforeUnload = () => {
      const data = new FormData();
      data.append("uuid", src.uuid);

      navigator.sendBeacon(`${BACKEND_URL}/delete-stream/`, data);
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);

      // Call deleteStream when the component unmounts
      const token = localStorage.getItem("token");
      const data = new FormData();
      data.append("uuid", src.uuid);
      if (token) data.append("token", token);

      navigator.sendBeacon(`${BACKEND_URL}/delete-stream/`, data);
    };
  }, [src.uuid]);

  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        overflow: "hidden",
      }}
    >
      <iframe
        allowFullScreen
        src={`${LIVEVIEW_SERVER_URL}/stream.html?src=${src.key}`}
        style={{
          width: "100%",
          height: "100%",
          border: "none",
          display: "block",
          overflow: "hidden",
        }}
        title="GoToRTC Stream"
      />
    </div>
  );
};

LiveViewVideoPlayer.propTypes = {
  src: PropTypes.shape({
    uuid: PropTypes.string.isRequired,
    key: PropTypes.string.isRequired,
  }).isRequired,
};

export default LiveViewVideoPlayer;
