import axios from "axios";
import {
  notifiToasterSuccess,
  notifiToasterWarn,
  notifiToasterInfo,
} from "../../components/common/UseToast";
import ApiSpec from "../features/EndPoints";
import moment from "moment";

// get Store

class Account {
  static updateTheme(data) {
    return async (dispatch) => {
      dispatch({ type: "UPDATE_THEME", payload: data });
    };
  }

  static updateDate(date) {
    return async (dispatch) => {
      dispatch({ type: "UPDATE_DATE", payload: date.toISOString() });
    };
  }
  static updateDateRange(dateRange) {
    return async (dispatch) => {
      dispatch({
        type: "UPDATE_DATE_RANGE",
        payload: dateRange,
      });
    };
  }

  static toglleSidebar(data) {
    return async (dispatch) => {
      dispatch({ type: "TOGGLE_SIDEBAR", payload: data });
    };
  }
  static toglleVideoSidebar(data) {
    return async (dispatch) => {
      dispatch({ type: "TOGGLE_VIDEO_SIDEBAR", payload: data });
    };
  }
  static toglleMuteNotification(data) {
    return async (dispatch) => {
      dispatch({ type: "TOGGLE_MUTE_NOTIFICATION", payload: data });
    };
  }

  static getClientList(data) {
    return async (dispatch) => {
      var token = localStorage.getItem("token");
      let headers = {
        Authorization: `Token ${token}`,
      };
      axios.get(ApiSpec.getClientList(data), { headers }).then((response) => {
        let client = [{ text: "All", value: "all" }];
        response.data.forEach((element) => {
          client.push({ text: element.name, value: element.id });
        });
        dispatch({ type: "CLIENT_LIST_DATA", payload: client });
      });
    };
  }

  static getStoresByAccount(data) {
    // var profile = JSON.parse(localStorage.getItem("profile"));
    return async (dispatch) => {
      var token = localStorage.getItem("token");
      let headers = {
        Authorization: `Token ${token}`,
      };
      await axios
        .get(ApiSpec.getStoresList(data), { headers })
        .then((response) => {
          let stores = [{ text: "All", value: "all" }];
          response.data.forEach((element) => {
            stores.push({ text: element.facility_name, value: element.id });
          });
          dispatch({ type: "STORE_LIST_DATA", payload: stores });
        });
    };
  }

  static getDevicesByAccount(data) {
    return async (dispatch) => {
      var token = localStorage.getItem("token");
      let headers = {
        Authorization: `Token ${token}`,
      };
      let userprofileId = JSON.parse(localStorage.getItem("profile")).id;
      axios
        .get(ApiSpec.getDevicesList(data, userprofileId), { headers })
        .then((response) => {
          let device = [{ text: "All", value: "all" }];
          response.data.forEach((element) => {
            device.push({ text: element.device_name, value: element.id });
          });

          dispatch({ type: "DIVICES_LIST_DATA", payload: device });
        });
    };
  }

  static getCamerasByStore(data) {
    return async (dispatch) => {
      var token = localStorage.getItem("token");
      let headers = {
        Authorization: `Token ${token}`,
      };

      axios
        .get(ApiSpec.getCamerasByStore(data), { headers })
        .then((response) => {
          let device = [{ text: "All", value: "all" }];
          response.data.forEach((element) => {
            device.push({ text: element.camera_name, value: element.id });
          });

          dispatch({ type: "CAMERAS_LIST_DATA", payload: device });
        });
    };
  }

  static getIpCamerasByNvr(data) {
    return async (dispatch) => {
      var token = localStorage.getItem("token");
      let headers = {
        Authorization: `Token ${token}`,
      };

      axios
        .get(ApiSpec.getIpCamerasByNvr(data), { headers })
        .then((response) => {
          let device = [];
          response.data.forEach((element) => {
            device.push({ text: element.camera_name, value: element.id });
          });

          dispatch({ type: "IP_CAMERAS_LIST", payload: device });
        });
    };
  }
  static updatePageSize(data) {
    return async (dispatch) => {
      dispatch({ type: "UPDATE_PAGE_SIZE", payload: data });
    };
  }
  static downloadExcelReport(data) {
    return async (dispatch) => {
      var token = localStorage.getItem("token");
      let headers = {
        Authorization: `Token ${token}`,
      };
      const date = moment(data.date);
      const formattedDate = date.format("YYYY-MM-DD");
      await axios
        .get(ApiSpec.downloadCultFitReport(data.id, formattedDate), {
          headers,
          responseType: "blob",
        })
        .then((response) => {
          // Create a URL for the blob
          const url = window.URL.createObjectURL(new Blob([response.data]));
          // Create a new anchor element
          const a = document.createElement("a");
          a.href = url;
          // Set the file name
          a.download = "Cult_Fit_Daily_Report.xlsx"; // Set a dynamic name as needed
          // Append the anchor to the document
          document.body.appendChild(a);
          // Trigger the download
          a.click();
          // Clean up
          a.remove();
          window.URL.revokeObjectURL(url);
          notifiToasterSuccess("Report Sent Successfully !!");
        })
        .catch((error) => {
          console.error("There was an error downloading the report:", error);

          notifiToasterWarn("Report Sent Failed");
        });
    };
  }
}

export default Account;

export const reportDownloadByMail = (datevalue, type, mode, dtRange) => {
  notifiToasterSuccess("Your request has been successfully submitted.");
  if (mode.value == "day-wise") {
    return new Promise((resolve, reject) => {
      var token = localStorage.getItem("token");
      let headers = {
        Authorization: `Token ${token}`,
      };
      const date = moment(datevalue);
      const formattedDate = date.format("YYYY-MM-DD");
      var profile = JSON.parse(localStorage.getItem("profile"));
      axios
        .get(ApiSpec.reportDownloadByMail(profile.id, formattedDate, type), {
          headers,
        })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          notifiToasterWarn("Report Sent Failed");
          reject(error);
        });
    });
  } else {
    return new Promise((resolve, reject) => {
      var token = localStorage.getItem("token");
      let headers = {
        Authorization: `Token ${token}`,
      };

      const datestart = moment(dtRange.startDate);
      const startDate = datestart.format("YYYY-MM-DD");
      const dateEnd = moment(dtRange.endDate);
      const endate = dateEnd.format("YYYY-MM-DD");

      var profile = JSON.parse(localStorage.getItem("profile"));
      axios
        .get(
          ApiSpec.reportDownloadByMailDateRange(
            profile.id,
            startDate,
            endate,
            type
          ),
          {
            headers,
          }
        )
        .then((response) => {
          notifiToasterSuccess("Report Sent Successfully !!");
          resolve(response);
        })
        .catch((error) => {
          notifiToasterWarn("Report Sent Failed");
          reject(error);
        });
    });
  }
};
export const reportDownloadByMailByCamera = (
  datevalue,
  type,
  mode,
  dtRange,
  cameraId
) => {
  notifiToasterSuccess("Your request has been successfully submitted.");
  if (mode.value == "day-wise") {
    return new Promise((resolve, reject) => {
      var token = localStorage.getItem("token");
      let headers = {
        Authorization: `Token ${token}`,
      };
      const date = moment(datevalue);
      const formattedDate = date.format("YYYY-MM-DD");
      var profile = JSON.parse(localStorage.getItem("profile"));
      axios
        .get(
          ApiSpec.reportDownloadByMailByCamera(
            profile.id,
            formattedDate,
            type,
            cameraId
          ),
          {
            headers,
          }
        )
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          notifiToasterWarn("Report Sent Failed");
          reject(error);
        });
    });
  } else {
    return new Promise((resolve, reject) => {
      var token = localStorage.getItem("token");
      let headers = {
        Authorization: `Token ${token}`,
      };

      const datestart = moment(dtRange.startDate);
      const startDate = datestart.format("YYYY-MM-DD");
      const dateEnd = moment(dtRange.endDate);
      const endate = dateEnd.format("YYYY-MM-DD");

      var profile = JSON.parse(localStorage.getItem("profile"));
      axios
        .get(
          ApiSpec.reportDownloadByMailDateRangeByCamera(
            profile.id,
            startDate,
            endate,
            type
          ),
          {
            headers,
          }
        )
        .then((response) => {
          notifiToasterSuccess("Report Sent Successfully !!");
          resolve(response);
        })
        .catch((error) => {
          notifiToasterWarn("Report Sent Failed");
          reject(error);
        });
    });
  }
};
export const getProfileIdByClient = (id) => {
  return new Promise((resolve, reject) => {
    var token = localStorage.getItem("token");
    let headers = {
      Authorization: `Token ${token}`,
    };
    axios
      .get(ApiSpec.getProfileIdByClient(id), {
        headers,
      })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        console.log(error);
        reject(error);
      });
  });
};
