import React from "react";
import PropTypes from "prop-types";
import { Card, CardContent, Typography, Box, Divider } from "@mui/material";

export const MaxFacilityTable = ({ data, classNameActive = true }) => {
  return (
    <Box
      sx={{
        borderLeft: { xs: "none", md: "solid grey 1px" },
        borderTop: { xs: "solid grey 1px", md: "none" },
        height: "90%",
        marginTop: 2,
        maxHeight: { md: "auto", lg: "500px" },
      }}
    >
      <Card
        sx={{
          height: "100%", // Take full height of the parent
          display: "flex",
          flexDirection: "column",
          overflow: "auto",
          boxShadow: 0,
          "&::-webkit-scrollbar": {
            width: "6px",
          },
          "&::-webkit-scrollbar-thumb": {
            backgroundColor: "rgba(0,0,0,.2)",
            borderRadius: "3px",
          },
        }}
      >
        <CardContent>
          <Typography variant="h6" gutterBottom>
            Top Facilities and Alert Counts
          </Typography>
          <Divider sx={{ mb: 2 }} />
          <Box
            sx={{
              overflowY: "auto", // Enable vertical scrolling
              maxHeight: "100%", // Ensure it fits the card's height
            }}
          >
            {data.map((facility, index) => (
              <Box
                key={index}
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  padding: "8px 0", // Add padding between entries
                  borderBottom: "1px solid #e0e0e0", // Optional border for separation
                }}
              >
                <p
                  style={{
                    color: "var(--black-white-darkmode)",
                  }}
                >
                  {facility.facility_name}
                </p>
                <p style={{ color: "var(--black-white-darkmode)" }}>
                  {facility.alert_count}
                </p>
              </Box>
            ))}
          </Box>
        </CardContent>
      </Card>
    </Box>
  );
};

// Defining the propTypes for the component
MaxFacilityTable.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      alertCount: PropTypes.number.isRequired,
    })
  ).isRequired,
  classNameActive: PropTypes.bool,
};
