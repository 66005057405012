import axios from "axios";
import ApiSpec from "../features/EndPoints";
import { notifiToasterWarn } from "../../components/common/UseToast";

/**
 * Handles API errors and shows appropriate notifications.
 * @param {Object} error - The error object from the API request.
 */
function handleAPIError(error) {
  if (error.response) {
    const { status, data } = error.response;
    if (status === 500) {
      notifiToasterWarn(
        "Our server is facing some issue. Please try again later."
      );
    } else if (status === 400) {
      notifiToasterWarn(data.error);
    } else {
      notifiToasterWarn(
        "Unable to process your request. Please refresh the page."
      );
    }
  } else {
    notifiToasterWarn(
      "Unable to process your request. Please refresh the page."
    );
  }
  throw error;
}
class LiveViewAction {
  /**
   * Retrieves live feed of IP cameras based on the given data and quality.
   * @param {Object} data - The camera data.
   * @param {string} quality - The quality of the stream.
   * @returns {Function} Returns a dispatch function.
   */
  static getLiveViewByIpCameras(data, quality) {
    const profile = JSON.parse(localStorage.getItem("profile"));

    return async (dispatch) => {
      try {
        const token = localStorage.getItem("token");
        const headers = { Authorization: `Token ${token}` };
        const account_id = JSON.parse(localStorage.getItem("profile")).account
          .account_id;
        // Check if data.id is not "all"
        if (data.id !== "all") {
          // Make API request
          const response = await axios.get(
            ApiSpec.getLiveStreamWithControls(
              profile.id,
              data,
              quality,
              account_id
            ),
            { headers }
          );

          // Handle response
          const responseData = response.data.data;

          if (responseData.length === 0) {
            notifiToasterWarn(
              "Unable to process your request. Please refresh the page."
            );
          } else {
            dispatch({
              type: "VMS_LIVE_FEEDS",
              payload: responseData,
            });

            // Save response in sessionStorage
            const existingItems =
              JSON.parse(sessionStorage.getItem("NewItems")) || [];
            const updatedItems = existingItems.concat(response.data);
            sessionStorage.setItem("NewItems", JSON.stringify(updatedItems));
          }
        }
      } catch (error) {
        handleAPIError(error);
      }
    };
  }

  //Action to stop the perticular stream
  static stopLiveStream(data) {
    return async (dispatch) => {
      const username = "demo";
      const password = "demo";
      const encodedCredentials = btoa(`${username}:${password}`);

      try {
        const response = await axios.get(
          ApiSpec.deleteLiveViewStreamHLS(data),
          {
            headers: {
              Authorization: `Basic ${encodedCredentials}`,
            },
          }
        );

        return response.data;
      } catch (error) {
        // Do nothing when the API call fails
      }
    };
  }

  static stopLiveStreamFfmpeg(data) {
    return async (dispatch) => {
      await axios.post(ApiSpec.stopLiveStream(data));
    };
  }
  //Action to clear the redux state
  static dissMissLiveData() {
    return async (dispatch) => {
      dispatch({
        type: "LIVE_FEEDS_REMOVE",
      });
    };
  }
  static removeCameraFromLiveViewData(data) {
    return async (dispatch) => {
      dispatch({
        type: "REMOVE_SELECTED_LIVE_FEEDS_FROM_PLAYLIST",
        payload: data,
      });
    };
  }
}

export default LiveViewAction;
